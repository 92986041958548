import { LoaderPlugin } from './plugin';
import { AxiosInstance } from 'axios';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $http: AxiosInstance;
  }
}

/**
 * Creates the Loader plugin.
 *
 * @returns An instance of LoaderPlugin
 */
export const createLoader = () => {
  return new LoaderPlugin();
};
