<script setup lang="ts">
import * as _ from 'lodash-es';
import { computed, watch, ref, onBeforeUnmount, onMounted, onBeforeMount } from 'vue';
import { usePrimeVue } from 'primevue/config';
import AppTopbar from './AppTopbar.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import { storeToRefs } from 'pinia';
import AppProfileSidebar from './AppProfileSidebar.vue';
import { useLayout } from '@/layout/composables/layout';
import { useRoute, useRouter } from 'vue-router';
import { useRootStore, useEntityStore, useUserStore } from '@/core/stores';
import { useAuthStore } from '@repo/vue/stores';
import ProgressSpinner from 'primevue/progressspinner';
import { User } from 'oidc-client-ts';
import { container as WidgetContainerModal } from 'jenesius-vue-modal';
import { Icon } from '@iconify/vue';
import { DxLoader, DxLoaderBar, DxLoaderBlock, DxLoaderSpinner } from '@repo/vue/components';

const rootStore = useRootStore();
const entityStore = useEntityStore();
const userStore = useUserStore();
const router = useRouter();
const authStore = useAuthStore();

const { currentEntity } = storeToRefs(entityStore);
const { currentUser } = storeToRefs(userStore);
const { userProfileId } = storeToRefs(rootStore);
const { isLoading } = storeToRefs(authStore);

const route = useRoute();
const $primevue = usePrimeVue();

const { layoutConfig, layoutState, isSidebarActive, isDarkTheme } = useLayout();
const outsideClickListener = ref<any>(null);
const sidebarRef = ref<any>(null);
//const isLoading = ref<boolean>(true);
const topbarRef = ref<any>(null);

//watches
watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

watch(
  userProfileId,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await userStore.fetchCurrentUser();
    }
  },
  { immediate: true }
);

//methods
const clearRootStoreData = () => {
  const search = window.location.search;
  if ((_.includes(search, 'code=') || _.includes(search, 'error=')) && _.includes(search, 'state=')) {
    rootStore.clearCurrentUser();
    rootStore.clearCurrentEntity();
    console.log('rootStore data reset');
  }
};

const setRootStoreData = (user: User) => {
  rootStore.$patch({
    userProfileId: user.profile?.sub
  });

  console.log('rootStore data saved');
};

authStore.$subscribe(
  (mutation, state) => {
    const user = state.user;
    if (user?.access_token) {
      if (user && user.access_token) {
        clearRootStoreData();
        setRootStoreData(user);
        //setEventStoreData(user);
      }
    }
  },
  { immediate: true }
);

//hooks
onMounted(() => {
  if (currentEntity && currentEntity.value?.id) {
    router.push({ name: 'files' });
  } else {
    router.push({ name: 'entities' });
  }
});

onBeforeMount(async () => {
  if (route.query.entityId) {
    rootStore.setEnityId(route.query.entityId.toString());
  }
  currentEntity.value = await entityStore.fetchCurrentEntity();
  if (currentEntity.value?.id) {
    router.push({ name: 'files' });
  }
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const containerClass = computed(() => {
  return {
    'layout-light': layoutConfig.colorScheme.value === 'light',
    'layout-dim': layoutConfig.colorScheme.value === 'dim',
    'layout-dark': layoutConfig.colorScheme.value === 'dark',
    'layout-colorscheme-menu': layoutConfig.menuTheme.value === 'colorScheme',
    'layout-primarycolor-menu': layoutConfig.menuTheme.value === 'primaryColor',
    'layout-transparent-menu': layoutConfig.menuTheme.value === 'transparent',
    'layout-overlay': layoutConfig.menuMode.value === 'overlay',
    'layout-static': layoutConfig.menuMode.value === 'static',
    'layout-slim': layoutConfig.menuMode.value === 'slim',
    'layout-slim-plus': layoutConfig.menuMode.value === 'slim-plus',
    'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
    'layout-reveal': layoutConfig.menuMode.value === 'reveal',
    'layout-drawer': layoutConfig.menuMode.value === 'drawer',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive.value,
    'layout-mobile-active': layoutState.staticMenuMobileActive.value,
    'p-ripple-disabled': $primevue.config.ripple === false,
    'layout-sidebar-active': layoutState.sidebarActive.value,
    'layout-sidebar-anchored': layoutState.anchored.value
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event: Event) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.overlaySubmenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    //document.removeEventListener('click', outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event: Event) => {
  const sidebarEl = sidebarRef?.value.$el;
  const topbarEl = topbarRef?.value.$el.querySelector('.topbar-menubutton');

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
</script>

<template>
  <div :class="['layout-container', { ...containerClass }]">
    <!-- <AppSidebar ref="sidebarRef" /> -->

    <template v-if="isLoading">
      <div class="tw-flex tw-h-screen">
        <div class="tw-m-auto">
          <ProgressSpinner />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="layout-content-wrapper">
        <AppTopbar ref="topbarRef" />
        <div class="layout-content">
          <router-view></router-view>
        </div>
      </div>

      <AppProfileSidebar />
      <!-- <AppConfig /> -->

      <!-- <DxLoaderBlock :blocked="isLoadingSpinner" />
      <DxLoaderBar v-if="!isIFrame" />
      <DxLoaderSpinner />  -->
      <Toast />
      <ConfirmDialog>
        <template #message="slotProps">
          <Icon icon="line-md:confirm" :class="slotProps.message.icon" />
          <span class="p-confirm-dialog-message" v-html="slotProps.message.message"></span>
        </template>
      </ConfirmDialog>
      <DynamicDialog />
      <WidgetContainerModal />
      <div class="layout-mask"></div>
    </template>
  </div>
</template>
