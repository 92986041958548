import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuth } from '@repo/vue/plugins';

export const useAuthStore = defineStore('auth', () => {
  const {
    isLoading,
    isAuthenticated,
    user,
    idTokenClaims,
    error,
    loginWithPopup,
    loginWithRedirect,
    logout,
    getTokenWithPopup,
    getTokenSilently,
    handleCallback,
    checkSession
  } = useAuth();

  const state = {
    user,
    idTokenClaims,
    isAuthenticated,
    isLoading,
    error
  };
  const actions = {
    loginWithPopup,
    loginWithRedirect,
    logout,
    getTokenSilently,
    getTokenWithPopup,
    handleCallback,
    checkSession
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
