import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export const AuthConfig: UserManagerSettings = {
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  authority: import.meta.env.VITE_AUTH_SERVER,
  client_id: import.meta.env.VITE_AUTH_CLIENT,
  redirect_uri: `${window.location.origin}/callback`,
  post_logout_redirect_uri: window.location.origin,

  response_type: 'code',
  scope: import.meta.env.VITE_AUTH_SCOPE,

  loadUserInfo: true,

  silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
  automaticSilentRenew: true,

  monitorAnonymousSession: true,
  revokeTokensOnSignout: true,

  filterProtocolClaims: false
};
