import { toRefs, reactive, computed } from 'vue';

const layoutConfig = reactive({
  menuMode: 'static',
  menuTheme: 'colorScheme',
  colorScheme: 'light',
  theme: 'indigo',
  scale: 14
});

const layoutState = reactive({
  staticMenuDesktopInactive: true,
  overlayMenuActive: false,
  sidebarActive: false,
  anchored: false,
  overlaySubmenuActive: false,
  profileSidebarVisible: false,
  configSidebarVisible: false,
  staticMenuMobileActive: false,
  menuHoverActive: false,
  activeMenuItem: null
});

export const useLayout = () => {
  const setScale = (scale: number) => {
    layoutConfig.scale = scale;
  };

  const setActiveMenuItem = (item: any) => {
    layoutState.activeMenuItem = item.value || item;
  };

  const onMenuToggle = () => {
    console.log(layoutConfig.menuMode);
    if (layoutConfig.menuMode === 'overlay') {
      layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
    }

    if (window.innerWidth > 991) {
      layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
    } else {
      layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
    }
  };

  const onProfileSidebarToggle = () => {
    layoutState.profileSidebarVisible = !layoutState.profileSidebarVisible;
  };

  const onConfigSidebarToggle = () => {
    layoutState.configSidebarVisible = !layoutState.configSidebarVisible;
  };

  const isSidebarActive = computed(
    () => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive || layoutState.overlaySubmenuActive
  );

  const isDarkTheme = computed(() => layoutConfig.colorScheme === 'dark');

  const isDesktop = computed(() => window.innerWidth > 991);

  const isSlim = computed(() => layoutConfig.menuMode === 'slim');
  const isSlimPlus = computed(() => layoutConfig.menuMode === 'slim-plus');

  const isHorizontal = computed(() => layoutConfig.menuMode === 'horizontal');

  return {
    layoutConfig: toRefs(layoutConfig),
    layoutState: toRefs(layoutState),
    setScale,
    onMenuToggle,
    isSidebarActive,
    isDarkTheme,
    setActiveMenuItem,
    onProfileSidebarToggle,
    onConfigSidebarToggle,
    isSlim,
    isSlimPlus,
    isHorizontal,
    isDesktop
  };
};
