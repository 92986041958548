import { api } from '@repo/core/config';
import { User } from '@/core/models';

export const useUserService = () => {
  // const get = async (parameters?: string): Promise<User[]> => {
  //   parameters = parameters || '';
  //   try {
  //     const response = await api.get('users' + parameters);
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     throw err;
  //   }
  // };

  const getCurrent = async (): Promise<User> => {
    try {
      const response = await api.get('user/getCurrent');

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // const post = async (data: User[], options?: any) => {
  //   try {
  //     if (!options) options = {};
  //     options.autoSave = options.autoSave != null ? options.autoSave : true;
  //     const response = await api.post('users', data, { headers: options });
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     throw err;
  //   }
  // };

  // const updateCurrent = async (data: User) => {
  //   try {
  //     const response = await api.post('users/updateCurrent', data);
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     throw err;
  //   }
  // };

  // const deleteUsers = async (data: string[]) => {
  //   try {
  //     const response = await api.delete('users/keys', { data: '"' + data + '"' });
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     throw err;
  //   }
  // };

  // const search = async (email: string) => {
  //   try {
  //     const response = await api.get(`users/search/${email}`);
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     throw err;
  //   }
  // };

  return {
    //get,
    getCurrent
    // post,
    // updateCurrent,
    // deleteUsers,
    // search
  };
};
