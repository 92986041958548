import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useUserService } from '@/core/services';
import { User } from '@/core/models';

export const useUserStore = defineStore('user', () => {
  const userService = useUserService();

  // state
  const currentUser = ref<User>(<User>{});

  // getters

  // actions
  const fetchCurrentUser = async () => {
    const result = await userService.getCurrent();
    currentUser.value = result;
    return result;
  };

  const state = { currentUser };
  const actions = {
    fetchCurrentUser
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
