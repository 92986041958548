import { App } from 'vue';
import { api } from '@repo/core/config';
import { useDxProgress } from '@repo/vue/utils';

// https://www.digitalocean.com/community/tutorials/add-loading-indicators-to-your-vuejs-application
// https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/components/nuxt-loading-indicator.ts
export class LoaderPlugin {
  install(app: App) {
    app.config.globalProperties.$http = api;

    const http = app.config.globalProperties.$http;
    const router = app.config.globalProperties.$router;

    // stores
    const { startLoader, stopLoader } = useDxProgress();

    // hooks
    router.beforeResolve((to: any, from: any, next: any) => {
      // If this isn't an initial page load.
      if (to.name) {
        // Start the route progress bar.
        startLoader();
      }
      next();
    });

    router.afterEach((to: any, from: any) => {
      // Complete the animation of the route progress bar.
      stopLoader();
    });

    // before a request is made start the nprogress
    http.interceptors.request.use(
      (config) => {
        startLoader(config.headers['autoSave']);
        return config;
      },
      (error) => {
        stopLoader();
        return Promise.reject(error);
      }
    );

    // before a response is returned stop nprogress
    http.interceptors.response.use(
      (response) => {
        stopLoader();
        return response;
      },
      (error) => {
        stopLoader();
        return Promise.reject(error);
      }
    );
  }
}
