<script setup lang="ts">
import * as _ from 'lodash-es';
import { onBeforeUnmount, onMounted } from 'vue';

// events
const onPreloadError = (event: Event) => {
  event.preventDefault();
  console.log('onPreloadError');
  if (window.parent !== window.top) {
    window.parent.location.reload();
  } else {
    window.location.reload();
  }
};

// hooks
onMounted(() => {
  window.addEventListener('vite:preloadError', onPreloadError);
});

onBeforeUnmount(() => {
  window.removeEventListener('vite:preloadError', onPreloadError);
});
</script>

<template>
  <RouterView />
</template>

<style scoped lang="scss"></style>
