/* eslint-disable require-await */
import * as _ from 'lodash-es';
import { App, Ref, ref } from 'vue';
import { Router } from 'vue-router';
import { AppState, AuthPluginOptions, AuthVueClient, AuthVueClientOptions } from './interfaces';
import { AUTH_INJECTION_KEY, AUTH_TOKEN } from './token';
import {
  IdTokenClaims,
  SigninPopupArgs,
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutRedirectArgs,
  User
} from 'oidc-client-ts';
import { bindPluginMethods } from './utils';
import { AuthClient } from '@repo/core/security';

export const client: Ref<AuthVueClient> = ref(<any>{});

// @link https://github.com/auth0/auth0-vue/tree/main/src
export class AuthPlugin implements AuthVueClient {
  private _client!: AuthClient;
  public isLoading: Ref<boolean> = ref(true);
  public isAuthenticated: Ref<boolean> = ref(false);
  public user: Ref<User | undefined> = ref({} as User);
  public idTokenClaims = ref<IdTokenClaims | undefined>();
  public error = ref<Error | null>(null);

  constructor(
    private clientOptions: AuthVueClientOptions,
    private pluginOptions?: AuthPluginOptions
  ) {
    // Vue Plugins can have issues when passing around the instance to `provide`
    // Therefor we need to bind all methods correctly to `this`.
    bindPluginMethods(this, ['constructor']);
  }

  install(app: App) {
    this._client = new AuthClient({
      ...this.clientOptions
    });

    this.__checkSession(app.config.globalProperties.$router).then((user) => user);

    app.config.globalProperties[AUTH_TOKEN] = this;
    app.provide(AUTH_INJECTION_KEY, this as AuthVueClient);

    client.value = this as AuthVueClient;
  }

  async loginWithRedirect(options?: SigninRedirectArgs): Promise<void> {
    return this._client.loginWithRedirect(options);
  }

  async loginWithPopup(options?: SigninPopupArgs): Promise<User> {
    return this.__proxy(() => this._client.loginWithPopup(options));
  }

  async logout(options?: SignoutRedirectArgs): Promise<void> {
    return this.__proxy(() => this._client.logout(options));
  }

  async getTokenSilently(options?: SigninSilentArgs): Promise<string | undefined> {
    return this.__proxy(async () => {
      return this._client.getTokenSilently(options);
    });
  }

  async getTokenWithPopup(options?: SigninPopupArgs): Promise<string | undefined> {
    return this.__proxy(async () => {
      return this._client.getTokenWithPopup(options);
    });
  }

  async checkSession(options?: SigninSilentArgs): Promise<void> {
    return this.__proxy(async () => this._client.checkSession(options));
  }

  async handleCallback(url?: string | undefined): Promise<void | User> {
    return this.__proxy(() => this._client.handleCallback(url));
  }

  private async __checkSession(router?: Router) {
    const search = window.location.search;
    try {
      if (
        (_.includes(search, 'code=') || _.includes(search, 'error=')) &&
        _.includes(search, 'state=') &&
        !this.pluginOptions?.skipRedirectCallback
      ) {
        const user = await this.handleCallback();
        const state: AppState = user?.state as AppState;
        const target = state?.target ?? '/';

        window.history.replaceState({}, '', '/');

        if (router) {
          await router.push(target);
        }

        return user;
      } else {
        await this.checkSession();
      }
    } catch (e) {
      // __checkSession should never throw an exception as it will fail installing the plugin.
      // Instead, errors during __checkSession are propagated using the errors property on `useAuth`.

      window.history.replaceState({}, '', '/');

      if (router) {
        await router.push(this.pluginOptions?.errorPath || '/');
      }
    }
  }

  private async __refreshState() {
    this.isAuthenticated.value = await this._client.isAuthenticated();
    this.user.value = await this._client.getUser();
    this.idTokenClaims.value = await this._client.getIdTokenClaims();
    this.isLoading.value = false;
  }

  private async __proxy<T>(cb: () => T, refreshState = true) {
    let result;
    try {
      result = await cb();
      this.error.value = null;
    } catch (e) {
      this.error.value = e as Error;
      throw e;
    } finally {
      if (refreshState) {
        await this.__refreshState();
      }
    }

    return result;
  }
}
