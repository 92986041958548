import { User } from 'oidc-client-ts';
import { AuthClient, AuthConfig } from '@repo/core/security';

const authClient = new AuthClient(AuthConfig);

export const useAuthService = () => {
  const getUser = (): Promise<User | undefined> => {
    return authClient.getUser();
  };

  const login = (): Promise<void> => {
    return authClient.loginWithRedirect();
  };

  const logout = (): Promise<void> => {
    return authClient.logout();
  };

  const getToken = (): Promise<string | undefined> => {
    return authClient.getToken();
  };

  const getTokenSilently = (): Promise<string | undefined> => {
    return authClient.getTokenSilently();
  };

  const handleCallback = (url?: string | undefined): Promise<void | User> => {
    return authClient.handleCallback(url);
  };

  return {
    getUser,
    login,
    logout,
    getToken,
    getTokenSilently,
    handleCallback
  };
};
