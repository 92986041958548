import axios from 'axios';
import { ResourceConfig } from '@repo/core/resources';
import { setRequestInterceptor, setResponseInterceptor } from '@repo/core/security';

export const api = axios.create({
  baseURL: ResourceConfig.serverBase,
  headers: { 'Content-Type': 'application/json' }
});

setRequestInterceptor(api);

setResponseInterceptor(api);
