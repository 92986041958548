<script setup lang="ts">
import { computed, watch, ref, onBeforeUnmount, onMounted } from 'vue';
import AppProfileSidebar from './AppProfileSidebar.vue';
import { useLayout } from '@/layout/composables/layout';
import { usePrimeVue } from 'primevue/config';

const { layoutConfig, layoutState, isSidebarActive, isDarkTheme } = useLayout();
const $primevue = usePrimeVue();

//need to move this layout to main
const containerClass = computed(() => {
  return {
    'layout-light': layoutConfig.colorScheme.value === 'light',
    'layout-dim': layoutConfig.colorScheme.value === 'dim',
    'layout-dark': layoutConfig.colorScheme.value === 'dark',
    'layout-colorscheme-menu': layoutConfig.menuTheme.value === 'colorScheme',
    'layout-primarycolor-menu': layoutConfig.menuTheme.value === 'primaryColor',
    'layout-transparent-menu': layoutConfig.menuTheme.value === 'transparent',
    'layout-overlay': layoutConfig.menuMode.value === 'overlay',
    'layout-static': layoutConfig.menuMode.value === 'static',
    'layout-slim': layoutConfig.menuMode.value === 'slim',
    'layout-slim-plus': layoutConfig.menuMode.value === 'slim-plus',
    'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
    'layout-reveal': layoutConfig.menuMode.value === 'reveal',
    'layout-drawer': layoutConfig.menuMode.value === 'drawer',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive.value,
    'layout-mobile-active': layoutState.staticMenuMobileActive.value,
    'p-ripple-disabled': $primevue.config.ripple === false,
    'layout-sidebar-active': layoutState.sidebarActive.value,
    'layout-sidebar-anchored': layoutState.anchored.value
  };
});
</script>

<template>
  <div>
    <!-- <AppSidebar ref="sidebarRef" /> -->

    <div :class="['layout-container', { ...containerClass }]">
      <div class="layout-content-wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
