import { inject } from 'vue';
import './global';
import { AuthPlugin, AuthPluginOptions, AuthVueClient, AuthVueClientOptions } from './global';
import { AUTH_INJECTION_KEY, AUTH_TOKEN } from './token';

export * from './global';
export { AUTH_INJECTION_KEY } from './token';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    [AUTH_TOKEN]: AuthVueClient;
  }
}

/**
 * Creates the Auth plugin.
 *
 * @param clientOptions The Auth Vue Client Options
 * @param pluginOptions Additional Plugin Configuration Options
 * @returns An instance of AuthPlugin
 */
export const createAuth = (clientOptions: AuthVueClientOptions, pluginOptions?: AuthPluginOptions) =>
  new AuthPlugin(clientOptions, pluginOptions);

/**
 * Returns the registered Auth instance using Vue's `inject`.
 * @returns An instance of AuthVueClient
 */
export const useAuth = (): AuthVueClient => <AuthVueClient>inject(AUTH_INJECTION_KEY);
