import { ref } from 'vue';
import { useDxLoader } from '@repo/vue/utils';
import { createGlobalState } from '@vueuse/core';

export const useDxProgress = createGlobalState(() => {
  // utils
  const dxLoaderBar = useDxLoader({ throttle: 100 });
  const dxLoaderSpinner = useDxLoader({ throttle: 900 });

  // state
  const isLoadingBar = dxLoaderBar.isLoading;
  const isLoadingSpinner = dxLoaderSpinner.isLoading;
  const progressBar = dxLoaderBar.progress;
  const progressSpinner = dxLoaderSpinner.progress;
  const requestsCompleted = ref<number>(0);
  const requestsTotal = ref<number>(0);

  // actions
  const startLoader = (autoSave = false) => {
    if (requestsTotal.value === 0) {
      dxLoaderBar.start();
      if (!autoSave) {
        dxLoaderSpinner.start();
      }
    }
    requestsTotal.value++;
    dxLoaderBar.set(requestsCompleted.value / requestsTotal.value);
  };

  const stopLoader = () => {
    requestsCompleted.value++;
    if (requestsCompleted.value >= requestsTotal.value) {
      dxLoaderBar.finish();
      dxLoaderSpinner.finish();
      requestsCompleted.value = 0;
      requestsTotal.value = 0;
    } else {
      dxLoaderBar.set(requestsCompleted.value / requestsTotal.value);
    }
  };

  const clearLoader = () => {
    dxLoaderBar.clear();
    dxLoaderSpinner.clear();
  };

  return {
    progressBar,
    progressSpinner,
    isLoadingBar,
    isLoadingSpinner,
    requestsCompleted,
    requestsTotal,
    startLoader,
    stopLoader,
    clearLoader
  };
});
