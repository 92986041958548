import { RouteLocation } from 'vue-router';
import { watchEffectOnceAsync } from './utils';
import { client as authClient } from './plugin';
import { AuthVueClient } from './interfaces';
import { unref } from 'vue';
import { SigninRedirectArgs } from 'oidc-client-ts';

const createGuardHandler = async (
  client: AuthVueClient,
  to: RouteLocation,
  signInRedirectOptions?: SigninRedirectArgs
) => {
  const fn = async () => {
    if (unref(client.isAuthenticated)) {
      return true;
    }

    await client.loginWithRedirect({
      state: { target: to.fullPath },
      ...signInRedirectOptions
    });

    return false;
  };

  if (!unref(client.isLoading)) {
    return fn();
  }

  await watchEffectOnceAsync(() => !unref(client.isLoading));

  return fn();
};

export const authGuard = (to: RouteLocation) => {
  const auth = unref(authClient) as AuthVueClient;

  return createGuardHandler(auth, to);
};
