import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useEntityService } from '@/core/services';
import { Engagement, Entity } from '@/core/models'; //need to change to entitiy model

export const useEntityStore = defineStore('entity', () => {
  // services
  const entityService = useEntityService();

  // state
  const currentEntity = ref<Entity>({} as Entity);

  // getters

  // actions
  const fetchEntities = () => {
    return entityService.get();
  };

  const getEngagements = (key: string | undefined): Promise<Engagement[]> => {
    return entityService.getEngagements(key);
  };

  const fetchCurrentEntity = async () => {
    const result = await entityService.getCurrent();
    currentEntity.value = result;
    return result;
  };

  const saveEntities = async (data: Entity[], options?: any) => {
    const result = await entityService.post(data, options);
    await fetchEntities();
    return result;
  };

  // const openClient = (clientId: string | undefined) => {
  //   rootStore.$patch({
  //     clientId: clientId
  //   });
  // };

  const state = {
    currentEntity
  };

  const actions = {
    fetchEntities,
    fetchCurrentEntity,
    saveEntities,
    getEngagements
    //openClient
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEntityStore, import.meta.hot));
}
