<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, defineAsyncComponent, onBeforeMount, ref, watch } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useAuthStore } from '@repo/vue/stores';
import { User } from '@/modelsa';
import { useEntityStore, useRootStore, useUserStore } from '@/core/stores';

const { layoutState } = useLayout();

//const currentUser = ref<User | null>(null);

//stores
const rootStore = useRootStore();
const authStore = useAuthStore();
const entityStore = useEntityStore();
const userStore = useUserStore();

// state
const { isEntitySelected } = storeToRefs(rootStore);
const { currentEntity } = storeToRefs(entityStore);
const { currentUser } = storeToRefs(userStore);

// userStore.fetchCurrentUser().then((result) => {
//   currentUser.value = result;
// });

// methods
const onLogout = async () => {
  await authStore.logout({
    post_logout_redirect_uri: window.location.origin
  });
};
</script>

<template>
  <Sidebar
    v-model:visible="layoutState.profileSidebarVisible.value"
    position="right"
    class="layout-profile-sidebar w-full sm:w-25rem"
  >
    <div class="flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">Welcome</span>
      <span class="text-color-secondary font-medium mb-5">{{ currentUser.firstName }} {{ currentUser.lastName }}</span>

      <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span>
              <i class="pi pi-user text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Profile</span>
            </div>
          </a>
        </li>

        <li @click="onLogout">
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span>
              <i class="pi pi-power-off text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Sign Out</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </Sidebar>
</template>
