import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard } from '@repo/vue/plugins';
import { processGuard } from '@/shared/process.guard';
import AppLayout from '@/layout/AppLayout.vue';
import AppLanding from '@/layout/AppLanding.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: AppLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: '/entities',
        name: 'entities',
        component: () => import('@/views/main/Entities.vue')
      },
      {
        path: '/files',
        name: 'files',
        component: () => import('@/views/main/files/Files.vue')
      }
      // {
      //     path: '/tasklist',
      //     name: 'tasklist',
      //     component: () => import('@/views/apps/tasklist/Index.vue')
      // }
    ]
  },
  {
    path: '/',
    component: AppLanding,
    children: [
      {
        path: '/callback',
        name: 'callback',
        component: () => import('@/views/common/auth/CallBackView.vue')
      },
      {
        path: '/confirm-user-invitation',
        name: 'confirmUserInvitation',
        component: () => import('@/views/common/auth/ConfirmUserInvitation.vue')
      },
      {
        path: '/registration-completed',
        name: 'registrationComplete',
        component: () => import('@/views/common/auth/RegistrationComplete.vue')
      }

      // {
      //     path: '/chat',
      //     name: 'chat',
      //     component: () => import('@/views/apps/chat/Index.vue')
      // },
      // {
      //     path: '/tasklist',
      //     name: 'tasklist',
      //     component: () => import('@/views/apps/tasklist/Index.vue')
      // }
    ]
  }
  // {
  //   path: '/landing',
  //   name: 'landing',
  //   component: () => import('@/views/pages/Landing.vue')
  // },
  // {
  //   path: '/pages/notfound',
  //   name: 'notfound',
  //   component: () => import('@/views/pages/NotFound.vue')
  // },
  // {
  //   path: '/auth/login',
  //   name: 'login',
  //   component: () => import('@/views/pages/auth/Login.vue')
  // },
  // {
  //   path: '/auth/access',
  //   name: 'accessDenied',
  //   component: () => import('@/views/pages/auth/Access.vue')
  // },
  // {
  //   path: '/auth/error',
  //   name: 'error',
  //   component: () => import('@/views/pages/auth/Error.vue')
  // },
  // {
  //   path: '/auth/register',
  //   name: 'register',
  //   component: () => import('@/views/pages/auth/Register.vue')
  // },
  // {
  //   path: '/auth/forgotpassword',
  //   name: 'forgotpassword',
  //   component: () => import('@/views/pages/auth/ForgotPassword.vue')
  // },
  // {
  //   path: '/auth/newpassword',
  //   name: 'newpassword',
  //   component: () => import('@/views/pages/auth/NewPassword.vue')
  // },
  // {
  //   path: '/auth/verification',
  //   name: 'verification',
  //   component: () => import('@/views/pages/auth/Verification.vue')
  // },
  // {
  //   path: '/auth/lockscreen',
  //   name: 'lockscreen',
  //   component: () => import('@/views/pages/auth/LockScreen.vue')
  // },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'notfound',
  //   component: () => import('@/views/pages/NotFound.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes
});

export default router;
