import '@/assets/styles.scss';

import { createApp } from 'vue';
import App from '@/App.vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import PiniaLogger from 'pinia-logger';
import messages from '@intlify/unplugin-vue-i18n/messages';
import router from '@/router';
import { createLoader } from '@repo/vue/plugins';
import { createPrimeVue } from '@repo/vue/plugins';
import { AuthConfig } from '@repo/core/security';
import { createAuth } from '@repo/vue/plugins';

const pinia = createPinia();
pinia.use(
  PiniaLogger({
    expanded: false,
    showDuration: true,
    showTime: true,
    logErrors: true,
    showPineapple: true,
    showStoreName: true,
    disabled: import.meta.env.MODE === 'production'
    // use a filter to only log certain actions
    // filter: ({ name }) => !_.includes(['startLoader', 'stopLoader', 'clearLoader'], name)
    // alternatively, you can specify the actions you want to log
    // if undefined, all actions will be logged
    // actions: ['decrementCounter']
  })
);

/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */

const i18n = createI18n({
  locale: 'en-ZA',
  messages
});

const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(createPrimeVue());
app.use(createLoader());
app.use(createAuth(AuthConfig));
app.mount('#app');
