import { PrimeVuePlugin } from './plugin';

/**
 * Creates the Loader plugin.
 *
 * @returns An instance of LoaderPlugin
 */
export const createPrimeVue = () => {
  return new PrimeVuePlugin();
};
