<script setup lang="ts">
//import AppBreadcrumb from './AppBreadcrumb.vue';
import * as _ from 'lodash-es';
import { ref, onMounted, onBeforeMount, onBeforeUnmount, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useLayout } from '@/layout/composables/layout';
import { useUserStore, useEntityStore, useRootStore } from '@/core/stores';
import { ResourceConfig } from '@repo/core/resources';
import { useRouter, useRoute } from 'vue-router';

// components

// locale

// utils
const { onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle } = useLayout();

// stores

const userStore = useUserStore();
const entityStore = useEntityStore();
const rootStore = useRootStore();
const route = useRoute();
const router = useRouter();

// state
const { currentUser } = storeToRefs(userStore);
const { isEntitySelected, multipleEntities } = storeToRefs(rootStore);
const { currentEntity } = storeToRefs(entityStore);

// data
const outsideClickListener = ref<EventListenerOrEventListenerObject | null>(null);
const topbarMenuActive = ref<boolean>(false);
const userRole = ref<number | undefined>(0);

// computed
const profileTag = computed(() => {
  if (currentUser.value?.id) return _.toUpper(Array.from(currentUser.value.firstName)[0]);

  return '';
});

const topMenuTitle = computed(() => {
  if (currentEntity.value?.id) {
    return currentEntity.value.name;
  }

  return '';
});

// methods
const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      // if (isOutsideClicked(event)) {
      //   topbarMenuActive.value = false;
      // }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener.value);
    outsideClickListener.value = null;
  }
};

const showProfileSidebar = () => {
  onProfileSidebarToggle();
};
const onConfigButtonClick = () => {
  onConfigSidebarToggle();
};

const onBackToDraftworxClick = () => {
  window.open(ResourceConfig.webAngularUrl, '_blank');
};

const onBackToEntitiesClick = () => {
  if (route.path != '/files') return;

  rootStore.clearCurrentEntity();
  router.push('entities');
};

const loadUserData = async () => {
  // load adll user data
};

// watches
watch(
  () => currentUser.value?.id,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await loadUserData();
    }
  },
  { immediate: true }
);

// hooks
onMounted(() => {
  bindOutsideClickListener();

  userRole.value = currentUser.value.entityRole;
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();

  // const user = await userStore.fetchCurrentUser();
  // const currentEntity = await entityStore.fetchCurrentEntity();

  // if (user) {
  //   currentUser.value = user;
  //   profileTag.value = _.toUpper(Array.from(currentUser.value.firstName)[0])
  //   userRole.value = user.entityRole;
  // }
  // if (currentEntity) {
  //   topMenuTitle.value = currentEntity.name;
  // }
});
</script>

<template>
  <div class="layout-topbar">
    <div class="topbar-start">
      <!-- <Button type="button" class="topbar-menubutton p-link p-trigger" @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </Button> -->
      <!-- 
      <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb> -->
      <div class="text-900 text-xl font-semibold mb-3">{{ topMenuTitle }}</div>
    </div>

    <div class="topbar-end">
      <ul class="topbar-menu">
        <li v-if="userRole && userRole >= 200" class="ml-3 mr-3">
          <Button
            icon="pi pi-arrow-circle-left"
            label="Back to Draftworx"
            rounded
            severity="secondary"
            @click="onBackToDraftworxClick"
          ></Button>
        </li>
        <li class="topbar-search">
          <IconField icon-position="left">
            <InputIcon class="pi pi-search" />
            <InputText type="text" placeholder="Search" class="w-12rem sm:w-full" />
          </IconField>
        </li>
        <li class="ml-3">
          <Button icon="pi pi-cog" text rounded severity="secondary" @click="onConfigButtonClick"></Button>
        </li>
        <li v-if="route.path == '/files' && multipleEntities" class="ml-3 mr-3">
          <Button icon="pi pi-home" rounded text severity="secondary" @click="onBackToEntitiesClick"></Button>
        </li>
        <li class="topbar-profile">
          <Button type="button" class="p-link" @click="showProfileSidebar">
            <b alt="Profile">{{ profileTag }}</b>
          </Button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
