import { InjectionKey } from 'vue';
import { AuthVueClient } from './interfaces';

/**
 * @ignore
 */
export const AUTH_TOKEN = '$auth';

/**
 * Injection token used to `provide` the `AuthVueClient` instance. Can be used to pass to `inject()`
 *
 * ```js
 * inject(AUTH_INJECTION_KEY)
 * ```
 */
export const AUTH_INJECTION_KEY: InjectionKey<AuthVueClient> = Symbol(AUTH_TOKEN);
