import { ref } from 'vue';

export const useDxLoader = (opts: { throttle: number }) => {
  // data
  const progress = ref(0);
  const isLoading = ref(false);

  let _timer: any = null;
  let _throttle: any = null;

  const start = () => {
    clear();
    progress.value = 0;
    if (opts.throttle) {
      _throttle = setTimeout(() => {
        isLoading.value = true;
        _startTimer();
      }, opts.throttle);
    } else {
      isLoading.value = true;
      _startTimer();
    }
  };

  const finish = () => {
    progress.value = 100;
    _hide();
  };

  const set = (data: number) => {
    progress.value = Math.min(100, Math.ceil(data));
  };

  const clear = () => {
    clearInterval(_timer);
    clearTimeout(_throttle);
    _timer = null;
    _throttle = null;
  };

  const _increase = () => {
    if (progress.value >= 100) return;

    let rnd = 0;
    const stat = progress.value;
    if (stat >= 0 && stat < 25) {
      // Start out between 3 - 6% increments
      rnd = Math.random() * (5 - 3 + 1) + 3;
    } else if (stat >= 25 && stat < 65) {
      // increment between 0 - 3%
      rnd = Math.random() * 3;
    } else if (stat >= 65 && stat < 90) {
      // increment between 0 - 2%
      rnd = Math.random() * 2;
    } else if (stat >= 90 && stat < 99) {
      // finally, increment it .5 %
      rnd = 0.5;
    } else {
      // after 99%, don't increment:
      rnd = 0;
    }

    progress.value = Math.min(100, progress.value + Math.ceil(rnd));
  };

  const _hide = () => {
    clear();
    setTimeout(() => {
      isLoading.value = false;
      setTimeout(() => {
        progress.value = 0;
      }, 400);
    }, 500);
  };

  const _startTimer = () => {
    _timer = setInterval(() => {
      _increase();
    }, 100);
  };

  return {
    progress,
    isLoading,
    start,
    finish,
    set,
    clear
  };
};
