export const ResourceConfig = {
  webAngularUrl: import.meta.env.VITE_WEB_ANGULAR_URL,
  webVueUrl: import.meta.env.VITE_WEB_VUE_URL,
  authServer: import.meta.env.VITE_AUTH_SERVER,
  authClientId: import.meta.env.VITE_AUTH_CLIENT,
  serverBase: import.meta.env.VITE_RESOURCE_SERVER_BASE,
  directImportServerBase: import.meta.env.VITE_RESOURCE_DIRECT_IMPORT_SERVER_BASE,
  reportServer: import.meta.env.VITE_RESOURCE_REPORT_SERVER,
  printPreviewServer: import.meta.env.VITE_RESOURCE_PRINT_PREVIEW_SERVER,
  cyncSiteUrl: import.meta.env.VITE_RESOURCE_CYNC_SITE_URL,
  ipGeolocationApi: import.meta.env.VITE_RESOURCE_IP_GEO_LOCATION_API,
  teamViewer: import.meta.env.VITE_RESOURCE_TEAM_VIEWER,
  caseware: import.meta.env.VITE_RESOURCE_CASEWARE,
  registrationCode: import.meta.env.VITE_RESOURCE_REGISTRATION_CODE,
  elmahApiKey: import.meta.env.VITE_RESOURCE_API_KEY,
  elmahLogId: import.meta.env.VITE_RESOURCE_ELMAH_LOG_ID,
  termsAndConditionsVersion: import.meta.env.VITE_RESOURCE_TERMS_AND_CONDITIONS_VERSION,
  helpBaseUrl: import.meta.env.VITE_RESOURCE_HELP_BASE_URL,
  developmentMode: import.meta.env.VITE_RESOURCE_DEVELOPMENT_MODE,
  reCaptchaSiteKey: import.meta.env.VITE_RESOURCE_RECAPTCHA_SITE_KEY,
  enableCaseware: import.meta.env.VITE_RESOURCE_ENABLE_CASEWARE,
  enablePayments: import.meta.env.VITE_RESOURCE_ENABLE_PAYMENTS,
  enableRemoteSupport: import.meta.env.VITE_RESOURCE_ENABLE_REMOTE_SUPPORT,
  enableElmahLogging: import.meta.env.VITE_RESOURCE_ENABLE_ELMAH_LOGGING,
  lightThemeColor: import.meta.env.VITE_RESOURCE_LIGHT_THEME_COLOUR,
  darkThemeColor: import.meta.env.VITE_RESOURCE_DARK_THEME_COLOUR,
  enableAppSettings: import.meta.env.VITE_RESOURCE_ENABLE_APP_SETTINGS
};
